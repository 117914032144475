.login-wrapper {
    width: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-wrapper h2 {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid black;
    margin: 0;
    padding-bottom: 15px;
}

.input-wrapper {
    width: 100%;
    margin-bottom: 15px;
}