.cell {
    box-shadow:inset 0px 0px 0px 0.1em var(--primary-color); /*Lets us do inset shadows, border pertrudes out of cell bounds.*/
    width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}

.journal-cell {
    background-color: var(--primary-color);
    cursor: pointer;
}

.current-cell {
    box-shadow:inset 0px 0px 0px 3px var(--primary-color);
}

.create-capsule-popup {
    background-color: var(--secondary-color);
    border-radius: 10px;
    text-align: center;
    user-select: none;

    padding: 30px 50px;
}

.create-capsule-popup button {
    margin-top: 10px;
}

.select-popup {
    display: flex;
    flex-direction: column-reverse;
}

.select-popup-journal {
    cursor: pointer;
    font-size: large;
    flex: 1;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 60px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.select-popup-journal p {
    color: var(--secondary-color);
    font-size: 1.4em;
    font-weight: bold;
    margin: 0;
    margin-top: 30px;
}

.select-popup-capsule {
    cursor: pointer;
    font-size: large;
    flex: 1;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding: 60px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.select-popup-capsule p {
    color: var(--primary-color);
    font-size: 1.4em;
    font-weight: bold;
    margin: 0;
    margin-top: 30px;
}

.journal-icon, .capsule-icon {
    width: 100px;
}

.tooltip {
    visibility: hidden;
    pointer-events: none;
    user-select: none;
}

.tooltip-text {
    background-color: var(--primary-color);

    position: absolute;
    bottom: 150%;
    left: 50%;
    
    border-radius: 5px;

    /* For actual text */
    color: var(--secondary-color);
    text-align: center;
    font-size: 0.95em;
    padding: 5px 0;
}

.tooltip-arrow {
    display:block;
    width:0;
    height:0;
    position:absolute;

    top: 0;
    left: 50%;

    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid var(--primary-color);

    margin-top: -15px;
    margin-left: -15px;
}

/* We gotta style icons like this if we have a global color like we do in index.css */
.icon-primary {
    * {
        color: var(--primary-color);
    }
}

.icon-secondary {
    * {
        color: var(--secondary-color);
    }
}

.icon-secondary, .icon-primary {
    width: 10px;
}

.shake-anim {
    animation: shake 0.15s;
    animation-iteration-count: infinite; 
}

@keyframes shake {
    0% { transform: translateX(0) }
    25% { transform: translateX(2px) }
    50% { transform: translateX(-2px) }
    75% { transform: translateX(2px) }
    100% { transform: translateX(0) }
}

@media (min-width: 600px) {
    .icon-secondary, .icon-primary {
        width: 12px;
    }

    .select-popup {
        flex-direction: row;
        height: 50vh;
        min-height: 300px;
    }    

    .journal-icon, .capsule-icon {
        width: 150px;
        height: 300px
    }    
}