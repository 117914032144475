.accordion-wrapper { 
    position: relative;
    padding: 8px 10px;
}

.accordion-header {
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-header p {
    font-weight: 600;
    padding: 10px 0;
    margin: 0;
}

.accordion-content {
    overflow: hidden;
    max-height: 0;
    transition: all 0.4s ease-in-out;
}

.accordion-icon {
    color: var(--primary-color);
    width: 50px;
    min-width: 50px;
}

.expanded {
    max-height: 250px;
}