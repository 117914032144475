.navbar {
    background-color: var(--primary-color);
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
}

.left, .center, .right {
    min-width: 100px;
    width: 100%;
    display: flex;
    padding: 0 20px;
}

.center {
    justify-content: left; /* left aligned on mobile */
}

/* Here for desktop version, not used in mobile view */
.left {
    justify-content: left;
    display: none;
}

.right {
    justify-content: flex-end;
    width: 100%;
}

.title {
    margin: 0;
}

.title-link {
    color: var(--secondary-color);
    text-decoration: none;
}

.username-dropdown-wrapper {
    width: 100%;
    display: inline-flex;
    flex-direction: row-reverse;
}

.username-dropdown-container {
    user-select: none;
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    max-width: 100%;
}

.username-dropdown-username {
    color: var(--secondary-color);
    font-size: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.username-dropdown-arrow {
    color: var(--secondary-color);
    font-size: 0.9em;
    margin-top: 5px;
    flex-shrink: 0; /* Prevent the arrow from shrinking */
}

/* used so when you click off the dropdown on mobile, it wont click anything on the page */
.screencover {
    position: absolute;
    left: 0;
    top: 90px;
    background-color: transparent;
    width: 100%;
    height: calc(100vh - 90px);
    z-index: 99;
}

.dropdown {
    padding: 0;
    margin: 0;
    list-style: none;

    position: absolute;
    top: 90px;
    left: 0;
    user-select: none;

    width: 100%;
    z-index: 100;
    box-sizing: border-box;
}

.dropdown-link {
    display: block;
    text-align: center;
    width: 100%;
    background-color: var(--primary-color);
    color: var(--secondary-color);

    text-decoration: none;
    padding: 15px 0;
    font-size: 1.2em;
}

.dropdown-link:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.logo {
    margin-top: 6px;
    width: 80px;
    height: 80px;
}

/* DESKTOP CHANGES */
@media (min-width: 900px) {
    .left {
        display: block;
    }

    .center {
        justify-content: center;
    }

    .username-dropdown-wrapper {
        margin-right: 50px;
    }

    .username-dropdown-container {
        position: relative;
    }

    .dropdown {
        min-width: 150px;
        top: 30px;
        left: 50%;
        transform: translateX(calc(-50% - 10px));
    }

    .dropdown-link {
        padding: 10px 10px;
        text-align: left;
        font-size: 1.05em;
    }
}