.confirm-popup-wrapper {
    background-color: var(--secondary-color);
    border-radius: 10px;
    text-align: center;
    user-select: none;

    padding: 30px 50px;
} 

.confirm-popup-wrapper Button {
    margin-top: 15px;
}

.create-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.editor-wrapper {
    position: relative;
    width: 90%;
    max-width: 1450px;
}

.title-wrapper {
    margin: 60px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-wrapper> p {
    margin-top: 5px;
    font-size: 1.1em;
}

.title-wrapper > * {
    margin: 0;
}

.button-wrapper {
    margin: 30px 0;
}

.invalid-date-wrapper {
    height: calc(100vh - 90px - 40px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.invalid-date-wrapper p {
    font-size: 1.5em;
}

.CAT-BOX {
    pointer-events: none;
    user-select: none;
    position: absolute;
    right: 0;
    bottom: -46px;
    width: 170px;
    z-index: 2;
    opacity: 0.9;
    transition: opacity 0.2s ease;
}

.HIDE-THE-CAT {
    opacity: 0;
}