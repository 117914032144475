.screen-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0,0,0,0.5);

    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-wrapper {
    position: relative;
    margin: 0 10px;
    width: 100%;
    min-width: 200px;
    max-width: 600px;
    overflow: hidden;
}

.exit-button {
    position: absolute;
    top: 0;
    right: 0;

    background-color: transparent;
    border: none;

    width: 28px;
    height: 28px;

    margin: 15px 20px;

    cursor: pointer;
}