.change-pass-wrapper {
    width: 300px;
    margin: 0 auto;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-wrapper {
    width: 100%;
    margin-bottom: 15px;
}