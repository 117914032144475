.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    mask-image: linear-gradient(to right, rgba(0,0,0,0.1), rgba(0,0,0,1), rgba(0,0,0,0.1));
}

.carousel-item {
    overflow: hidden;
    color: var(--primary-color);
    opacity: 0.7;
    font-size: 1.1em;
    user-select: none;
    cursor: pointer;

    /* to center text */
    display: flex;
    align-items: center;
    justify-content: center;

    transition: margin-left 0.35s ease;
}

.selected-item {
    color: var(--primary-color);
    opacity: 1 !important;
    font-weight: bold;
    font-size: 1.35em;
}