.timeline-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;

    width: 100%;
    margin: 0 auto;

    overflow-x: hidden;
}

.year-selector {
    margin-bottom: 15px;
}

.timeline {
    display: grid;
    grid-template-columns: repeat(auto-fill, 18px);
    grid-auto-rows: 18px;
    justify-content: center; /*Horizontal align*/
    align-items: center; /*Vertical align*/
    gap: 4px;

    width: 100%;
}

/* tablets ish */
@media (min-width: 600px) {
    .timeline-wrapper {
        width: 380px; 
    }

    .timeline {
        grid-template-columns: repeat(auto-fill, 22px);
        grid-auto-rows: 22px;
    }
}