.view-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-container {
    overflow-wrap: break-word;
    white-space: normal;
    border-top: 0.1em solid var(--primary-color);
    margin-bottom: 30px;
    padding: 5px 0;

    width: 90%;
    max-width: 1000px;
    line-height: 25px;
}

.loading-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.title-wrapper {
    margin: 60px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-wrapper > p {
    margin-top: 5px;
    font-size: 1.1em;
}

.title-wrapper > * {
    margin: 0;
}