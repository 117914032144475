.policy-wrapper {
    max-width: 750px;
    margin: 30px auto;
    padding: 0 15px;
}

@media (min-width: 900px) {
    .policy-wrapper {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 10px;
        padding: 20px 30px;
    }
}