.date-title {
    margin-bottom: 8px;
}

.recents-wrapper {
    min-width: 100%;
    min-height: 150px;
    height: 100%;
}

.no-entries-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-entries {
    margin: 60px 15px;
    opacity: 0.6;
}

.recent-wrapper {
    margin: 30px auto;
    width: 80%;
}

.recent-text-wrapper {
    height: 70px;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.recent-text-content {
    word-wrap: break-word;
}

@media (min-width: 900px) {
    .recents-wrapper {
        overflow-y: scroll;
    }

    .date-title h3 {
        font-size: 1.45em;
    }
}