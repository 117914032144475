.ql-editor {
    min-height: 300px;
    font-family: Montserrat, sans-serif;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ql-container {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ql-toolbar {
    background-color: #eaecec;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    position: sticky;
    top: 90px; /* nav bar */
    z-index: 1;
}

.ql-formats {
    margin: 5px 0;
}