/* Global palette vars (overwritten in App.css) */
:root {
  --primary-color: #ffffff;
  --primary-color-darker: #ffffff;
  --secondary-color: #ffffff;
  --secondary-color-darker: #ffffff;
  --tertiary-color: #ffffff;
}

/* Change defualt font colour */
* {
  color: var(--primary-color);
}

body {
  background-color: var(--secondary-color);
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}