.button {
    position: relative;
    padding: 10px;
    width: 220px;
    height: 45px;
    font-size: large;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 15px;  
    cursor: pointer;
    z-index: 1;
    user-select: none;
}

.fill-container {
    width: 100% !important;
}

.normal {
    background-color: var(--primary-color);
    color: var(--secondary-color); 
    border: none;
    transition: background-color 0.25s ease;
}

.light {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    overflow: hidden;
    transition: color 0.19s ease-in;
}

.slide-hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--primary-color);
    transition: width 0.19s ease-in;
    z-index: -1;
}

/* Only apply hover styles when buttons aren't disabled*/
.normal:not([disabled]):hover {
    background-color: var(--primary-color-darker);
}

.slide-hover:not([disabled]):hover {
    color: var(--secondary-color);
}
.slide-hover:not([disabled]):hover:before {
    width: 100%;
}

.normal:disabled {
    opacity: 0.3;
    color: var(--secondary-color);
    cursor: not-allowed;
}

.light:disabled {
    cursor: not-allowed;
}