.landing-wrapper {
    overflow: hidden;
}

.landing-wrapper p {
    font-size: 1.2em;
}

.top-portion {
    margin: 50px auto;
    display: flex;
    justify-content: center;
}

.button-portion {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.button-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.button-portion p {
    font-size: 2em;
    font-family: Inter, Arial;
    text-align: center;
    margin: 0;
    margin-bottom: 15px;
}

.desktop-img-div {
    display: none;
}

.mobile-img-div {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-img-div img {
    user-select: none;
    z-index: 1;
    width: 60%;
    max-width: 400px;
}

.black-box {
    position: absolute;
    top: 50%;
    background-color: var(--primary-color);
    width: 100%;
    height: calc(50% + 1px); /* mobile bug causes 1 px space */
    z-index: -1;
}

.title-break {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    font-weight: normal;
    font-family: Inter, Arial;
    font-size: xx-large;
    margin: 0;
    text-align: center;
    font-size: 2.3em;
    padding: 65px 15px;
}

.explanation-paragraph {
    line-height: 33px;
    text-align: center;
    margin: 80px 35px;
}

.box {
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: 80px auto;
    max-width: 400px;
}

.box p {
    text-align: center;
    line-height: 33px;
    margin-top: 15px;
}

.box-title {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.box-title p {
    font-size: 1.55em;
    text-align: center;
    margin: 0;
}

.bottom-portion {
    text-align: center;
    margin: 90px 0;
    opacity: 0.5;
}

.bottom-portion a {
    color: blue;
}

@media (min-width: 900px) {
    .top-portion {
        position: relative;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 80px;
        width: 100%;
        margin: 95px auto;
    }

    .button-portion {
        max-width: 700px;
    }

    .button-portion p {
        font-size: 4.5em;
    }

    .button-container {
        margin: 0 auto;
        flex-direction: row;
        width: 80%;
        gap: 35px;
    }    

    .mobile-img-div {
        display: none;
    }

    .desktop-img-div {
        flex-grow: 1;
        display: block;
        min-width: 1100px;
        max-width: 1100px;
    }

    .desktop-img-div img {
        user-select: none;
        width: 100%;
        max-width: 100%;
    }

    .title-break {
        padding: 165px 15px;
        font-size: 4em;
    }

    .explanation-paragraph {
        font-size: 2em !important;
        max-width: 1000px;
        line-height: 53px;
        margin: 150px auto;
        padding: 150px 40px;
    }

    .box-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1000px;
        margin: 150px auto;
        gap: 90px;
    }

    .box {
        width: calc(50%);
        max-width: 450px;
        height: 200px;
        margin: 0;
    }
}