#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.navbar {
    position: sticky;
    top: 0;
    z-index: 99;
}

.content {
    flex-grow: 1;
}

.footer {
    flex-shrink: 0;
}