footer {
    background-color: var(--primary-color);
    color: var(--secondary-color);

    display: flex;
    justify-content: space-between;

    padding: 12px 10px;
    z-index: 99;
}

.copyright {
    user-select: none;
    margin: 0;
    color: var(--secondary-color);
    font-size: smaller;
    filter: brightness(70%);
}

.made-by {
    user-select: none;
    margin: 0;
    color: var(--secondary-color);
    font-size: smaller;
}

.made-by-text {
    color: var(--secondary-color);
    filter: brightness(70%);
}

.made-by a {
    font-weight: bold;

    text-decoration: none;
    color: inherit;
}

.made-by a:hover {
    text-decoration: underline;
}

/* DESKTOP CHANGES */
@media (min-width: 900px) {
    footer {
        padding: 12px 20px;
    }

    .copyright {
        font-size: medium;
    }

    .made-by {
        font-size: medium;
    }
}