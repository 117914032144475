.home-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 10px;
}

.home-container {
    margin-bottom: 30px; 
}

.create-entry-wrapper {
    position: relative;
}

.recents-wrapper {
    border-top: 2px solid var(--primary-color);
    margin: 0 auto;
    min-width: 90%;
    max-width: 90%;
}

.video {
    width: 100%;
    max-width: 400px;
    min-width: 200px;
}

#pane1 {
    margin-top: 20px;
}

#pane2 {
    margin-top: 60px;
    max-width: 1000px;
}

.recents-title {
    font-weight: normal;
    font-size: 1.3em;
}

.create-capsule-popup {
    background-color: var(--secondary-color);
    padding: 25px 40px;
    border-radius: 10px;
}

.create-capsule-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.entry-made {
    margin: 10px 0;
    opacity: 0.5;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 15px 0;
}

/* 
    This allows us to only center the content vertically when its useful.
    Without this, the page structure breaks if we shrink the screen bellow ~1025px
*/
@media (min-height: 1025px) and (min-width: 900px) {
    .home-container {
        height: calc(100vh - 90px - 50px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 900px) {
    .button-wrapper {
        flex-direction: row;
        justify-content: center;
    }   
    
    .entry-made {
        position: absolute;
    }

    .home-wrapper {
        margin: 0 30px;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    #pane1 {
        flex: 0 0 auto;
    }

    #pane2 {
        flex: 1 0;
    }

    .recents-wrapper {
        height: 600px;
    }
}